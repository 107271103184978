import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import GoogleAd from "../components/adsense"

const IndexPage = () => (
  <Layout>
    <SEO title="PublicFilings.net" keywords={[`public`, `documents`, `puc`]} />
    <div style={{float:"center"}}>
      <h1>Search Public Filings</h1>
    </div>

    <table>
      <thead>
        <tr>
          <th>Date</th>
          <th>State</th>
          <th>Agency</th>
          <th>Title</th>
        </tr>
      </thead>
      <tbody>
          <tr>
            <td>
              1/1/2019
            </td>
            <td>
              Texas
            </td>
            <td>
              PUC
            </td>
            <td>
              <a href="/gov/texas/puc/v247-power-affidavit-robert-pham-2019-02-13">AFFIDAVIT ON BEHALF OF V247 POWER CORPORATION</a>
            </td>
          </tr>
      </tbody>
    </table>

    <GoogleAd client="ca-pub-8265873079464306" slot="1" />

  </Layout>
)

export default IndexPage
